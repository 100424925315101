<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div>
            <p>{{ $t('deposit.fasa.header') }}</p>
            <DepositInfo :instData="instData" :noteData="noteData"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">{{ $t('deposit.fasa.form.header') }}</p>
            <el-form label-position="top" :model="fasapayForm" ref="fasapayForm" status-icon :rules="fasapayRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, fasapayForm.amount)" prop="amount">
                      <numeric-input
                        v-model="fasapayForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="fasapayForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div v-html="bankHTML"></div>
      </div>
      <div class="form-right">
        <div class="logo rectangle fasapay"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import { autoForm } from '@/util/getAutoForm';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiFasapay_payment } from '@/resource';

export default {
  name: 'fasaPay',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      fasapayForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      fasapayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'USC'],
      bankHTML: '',
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst2', { method: this.$t('deposit.default.channels.fasa') }),
        this.$t('deposit.reminder.inst3', { method: this.$t('deposit.default.channels.fasa') })
      ],
      noteData: [this.$t('deposit.reminder.note1', { method: this.$t('deposit.default.channels.fasa') })]
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.fasapayForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiFasapay_payment(
        {
          mt4Account: this.fasapayForm.accountNumber,
          operateAmount: this.fasapayForm.amount,
          applicationNotes: this.fasapayForm.notes
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['fasapayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.bankHTML = autoForm(result.data.msg);
                if (this.bankHTML.length > 0) {
                  this.$nextTick(function() {
                    if (document.getElementById('AutoForm')) {
                      document.getElementById('AutoForm').submit();
                    } else {
                      this.loading = false;
                      this.errorMessage(this.$t('deposit.default.failed'));
                    }
                  });
                } else {
                  this.loading = false;
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
